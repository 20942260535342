<template>
    <div
        :class="[
            'dropdown',
            isNight ? 'dropdown--night' : '',
            isRoot ? 'dropdown--root' : '',
        ]"
        v-click-outside="hideDropdown"
        @mouseenter="openDropdown"
        @mouseleave="hideDropdown"
    >
        <Button
            :class="['dropdown__btn', isView ? 'opened' : '']"
            :btn-style="btnStyle"
            :btn-size="btnSize"
            :iconName="'arrow-down'"
            @click="toggleDropdown"
        >
            <template v-if="type === 'links'">
                Еще
            </template>
            <template v-else>
                <span>
                    {{ links.find(el => el.isActive)?.btnName
                        ? links.find(el => el.isActive)?.btnName
                        : links.find(el => el.isActive)?.name
                    }}
                    <span
                        v-if="links.find(el => el.isActive)?.subName"
                        class="dropdown__btn-text f-caption"
                    >
                        {{ links.find(el => el.isActive)?.subName }}
                    </span>
                </span>
            </template>
        </Button>
        <Transition name="fade">
            <div v-if="isView" class="dropdown__list">
                <Simplebar
                    :class="[
                        'scrollbar',
                        isNight ? 'scrollbar--night' : '',
                        isRoot ? 'scrollbar--root' : '',
                    ]"
                    data-simplebar-auto-hide="false"
                >
                    <div
                        class="dropdown__item"
                        v-for="link in links"
                        :key="link.src"
                    >
                        <nuxt-link
                            v-if="type === 'links'"
                            class="dropdown__link"
                            :to="link.src"
                            :title="link.title"
                        >
                            {{ link.name }}
                        </nuxt-link>
                        <div
                            v-else-if="type === 'btns'"
                            :class="[
                                'dropdown__link',
                               link.isActive ? 'active' : '',
                            ]"
                            @click="dropdownClick(link)"
                        >
                            {{ link.name }}
                        </div>
                    </div>
                </Simplebar>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { TIMEOUT } from '~/utils/constants';
import Button from '~/components/ui/Button.vue';

const props = defineProps({
    links: { type: Array, required: true, readonly: true },
    type: { type: String, required: true, readonly: true },
    btnStyle: { type: String, required: false, readonly: true },
    btnSize: { type: String, required: false, readonly: true },
    activeIndex: { type: String, required: false, readonly: true },
    isNight: { type: Boolean, required: false, readonly: true },
    isRoot: { type: Boolean, required: false, readonly: true },
});

const isView = ref(false);
const timeout = ref();
const emit = defineEmits(['click', 'dropdownClick']);

function openDropdown() {
    timeout.value = setTimeout(() => {
        isView.value = true;
    }, TIMEOUT.MEDIUM);
}

function hideDropdown() {
    clearTimeout(timeout.value);
    isView.value = false;
}

function toggleDropdown() {
    isView.value = !isView.value;
}

function dropdownClick(link: { name: string; isActive: boolean }) {
    emit('dropdownClick', link);
	hideDropdown();
}
</script>

<style
    lang="scss"
    src="~/assets/scss/components/ui/dropdown.scss"
    scoped
></style>
<style
    lang="scss"
    src="~/assets/scss/components/ui/scrollbar.scss"
></style>
