<template>
    <footer
	    class="footer"
	    :style="
	        indexStore.footerBackground.length ?
	        `background-color: ${indexStore.footerBackground};` :
	        ''
		"
    >
        <div class="footer__container">
            <div class="footer__row">
                <div
                    v-if="indexStore.commonInfo.tel"
                    class="footer__col footer__col--left"
                >
                    <a
                        class="footer__contacts-link f-body"
                        :href="`tel:${formatTelNumber(
                            indexStore.commonInfo.tel.value
                        )}`"
                        :title="indexStore.commonInfo.tel.title"
                    >
                        {{ indexStore.commonInfo.tel.value }}
                    </a>
                </div>
                <div
                    v-if="indexStore.commonInfo.email"
                    class="footer__col footer__col--center"
                >
                    <a
                        class="footer__contacts-link f-body"
                        :href="`mailto:${indexStore.commonInfo.email.value}`"
                        :title="indexStore.commonInfo.email.title"
                    >
                        {{ indexStore.commonInfo.email.value }}
                    </a>
                </div>
                <div class="footer__col footer__col--right footer__col--tr">
                    <NuxtLink
                        v-if="indexStore.commonInfo.address"
                        class="footer__contacts-link f-body"
                        to="/contacts"
                    >
                        {{ indexStore.commonInfo.address }}
                    </NuxtLink>
                </div>

                <div class="footer__col footer__col--center footer__col--nav">
                    <div class="footer__nav">
                        <Dropdown
                            v-if="indexStore.commonInfo.footerMenu"
                            class="dropdown--top"
                            :links="indexStore.commonInfo.footerMenu"
                            :type="'links'"
                            :btn-style="'secondary-root'"
                            :btn-size="'medium'"
                            :is-root="true"
                        />
                        <Button
	                        v-if="indexStore.commonInfo.presentationUrl"
                            class="footer__btn"
                            :btn-style="'secondary-fill-root'"
                            :btn-size="'medium'"
                            :download-link="
                                indexStore.commonInfo.presentationUrl
                            "
                        >
                            Скачать презентацию
                        </Button>
                    </div>
                </div>
                <div class="footer__col footer__col--right">
                    <div class="footer__soc">
                        <ul class="footer__soc-list">
                            <li
                                v-for="(item, index) in indexStore.commonInfo
                                    .socials"
                                :key="`socials-${item.id ? item.id : index}`"
                                class="footer__soc-item"
                            >
                                <a
                                    class="footer__soc-link f-body"
                                    :href="item.src"
                                    :aria-label="item.name"
                                    :title="item.title"
                                    target="_blank"
                                >
                                    <NuxtImg
                                        v-if="!indexStore.nightTheme"
                                        :src="item.icon"
                                        :alt="item.name"
                                        :title="item.name"
                                        loading="lazy"
                                        densities="x1 x2"
                                        format="webp"
                                        placeholder
                                    />
                                    <NuxtImg
                                        v-else
                                        :src="item.iconDarkTheme"
                                        :alt="item.name"
                                        :title="item.name"
                                        loading="lazy"
                                        densities="x1 x2"
                                        format="webp"
                                        placeholder
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="footer__col footer__col--left">
                    <NuxtLink class="footer__link f-body" to="/policy">
                        Политика конфиденциальности
                    </NuxtLink>
                </div>
            </div>
            <div class="footer__row footer__row--center m-t-64">
                <div
                    class="footer__copyright f-body"
                    itemscope
                    itemtype="https://schema.org/WebSite"
                    vocab="https://schema.org/"
                    typeof="WebSite"
                >
                    <meta
                        itemprop="copyrightYear"
                        property="copyrightYear"
                        :content="`${year}`"
                    >
                    <meta
                        itemprop="copyrightHolder"
                        property="copyrightHolder"
                        content="Makeit"
                    >
                    <span>{{ `@ ${year} Makeit.` }}</span>
                    <span>Не является публичной офертой.</span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { formatTelNumber } from '~/utils/formatTelNumber';
import Button from '~/components/ui/Button.vue';
import Dropdown from '~/components/ui/Dropdown.vue';
import { useCommonStore } from '~/store';
const indexStore = useCommonStore();

const year = computed(() => {
    const date = new Date();
    return date.getFullYear();
});
</script>

<style
    lang="scss"
    src="~/assets/scss/components/layout/footer.scss"
    scoped
></style>
