export default defineNuxtRouteMiddleware((to) => {
    const path = to.path;
    let normalizedPath = path.toLowerCase().replace(/\/$/, '') || '/';

    if (normalizedPath === '/') {
        normalizedPath = '/';
    }

    if (normalizedPath !== path) {
        return navigateTo(normalizedPath, { replace: true });
    }
});
