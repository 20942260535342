export default defineNuxtPlugin((nuxtApp) => {
    // replace v-html due to ssr
    nuxtApp.vueApp.directive('thtml', {
        mounted: (el: HTMLElement, binding: any, vnode: any) => {
            el.innerHTML = binding.value;
        },
        getSSRProps(binding: any) {
            return {
                innerHTML: binding.value
            }
        }
    });
});
