import routerOptions0 from "/makeitapp/node_modules/nuxt/dist/pages/runtime/router.options.js";
const configRouterOptions = {
  trailingSlash: false,
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = false
export default {
...configRouterOptions,
...routerOptions0,
}