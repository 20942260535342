import { IS_DEV } from '~/utils/constants';
import { defineStore } from 'pinia';
export const useFeedbackStore = defineStore('feedback', {
    state: () => ({
        isLoading: false as boolean,
    }),
    actions: {
        async sendFeedbackForm(formData: FormData) {
            if (!IS_DEV) {
                this.isLoading = true;

                try {
                    const data: { [key: string]: string } = await $fetch(
                        `/api/forms/feedback`, {
                            method: 'post',
                            body: formData,
                        }
                    );

                    return data;
                } catch (error) {
                    console.error('ERROR on sendFeedbackForm: ', error);
                } finally {
                    this.isLoading = false;
                }
            }
        },
    },
});
