import { IS_DEV } from '~/utils/constants';
import { defineStore } from 'pinia';
import type { ISpline, ISplineResponse } from '~/types';

export const useSplinesStore = defineStore('splines', {
    state: () => ({
        splines: [] as ISpline[],
        spline: {} as ISpline,
        mockSplines: [
            {
                id: '0',
                type: 'company',
                // src: 'https://prod.spline.design/iD8BpZLE7RJJ8sip/scene.splinecode',
                src: '/splines/spline-company.splinecode',
                spline_path: '',
                anchor: '',
            },
            {
                id: '1',
                type: 'contacts',
                // src: 'https://prod.spline.design/iohJEcShhTET58ZS/scene.splinecode',
                src: '/splines/spline-contacts.splinecode',
                spline_path: '',
                anchor: '',
            },
            {
                id: '2',
                type: 'service',
                // src: 'https://prod.spline.design/HxIOsniSG3-3nS4q/scene.splinecode',
                src: '/splines/spline-services-analytic.splinecode',
                spline_path: '',
                anchor: 'analytic',
            },
            {
                id: '3',
                type: 'service',
                // src: 'https://prod.spline.design/lcLt8-u6bHaR6mFd/scene.splinecode',
                src: '/splines/spline-services-design.splinecode',
                spline_path: '',
                anchor: 'design',
            },
            {
                id: '4',
                type: 'service',
                // src: 'https://prod.spline.design/dWAnuAsrsXsQwHdv/scene.splinecode',
                src: '/splines/spline-services-development.splinecode',
                spline_path: '',
                anchor: 'development',
            },
            {
                id: '5',
                type: 'service',
                // src: 'https://prod.spline.design/fHNRdSF-xjUbxlBW/scene.splinecode',
                src: '/splines/spline-services-progress.splinecode',
                spline_path: '',
                anchor: 'progress',
            },
            {
                id: '6',
                type: 'banner',
                // src: 'https://prod.spline.design/5sVaJWbEYZ-dxivD/scene.splinecode',
                spline_path: '',
                src: '/splines/spline-banner.splinecode',
            },
        ] as ISpline[],
        mockSpline: {
            id: '0',
            type: 'company',
            src: '/splines/spline-company.splinecode',
            spline_path: '',
            anchor: '',
        } as ISpline,
        isLoading: false as boolean,
        totalCount: 0 as number,
    }),
    actions: {
        setSplines(splines: ISpline[]) {
            this.splines = splines;
        },

        setSpline(spline: ISpline) {
          this.spline = spline;
        },

        async fetchSplines(): Promise<ISpline[] | null> {
            if (IS_DEV) {
                this.setSplines(this.mockSplines);
                this.totalCount = this.mockSplines.length;
                return this.mockSplines;
            } else {
                this.isLoading = true;

                try {
                    const data: ISplineResponse = await $fetch(
                        `/api/splines`
                    );

                    if (data) {
                        this.totalCount = data.total_count;
                        this.setSplines(data.result);
                        if (data.result) {
                            return data.result;
                        }

                        return null;
                    }
                    return null;
                } catch (error) {
                    console.error('ERROR on fetchSplines: ', error);
                    return null;
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async findSpline(id: number | string): Promise<ISpline | null> {
            if (IS_DEV) {
                this.setSpline(this.mockSpline);
                return this.mockSpline;
            } else {
                this.isLoading = true;

                try {
                    const data: ISpline = await $fetch(
                        `/api/splines/${id}`
                    );

                    if (data) {
                        this.setSpline(data);
                        return data;
                    }

                    return null;
                } catch (error) {
                    console.error('ERROR on findSpline: ', error);
                    return null;
                } finally {
                    this.isLoading = false;
                }
            }
        },
    },
});
