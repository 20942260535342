<template>
  <section ref="nightBlock" class="night-mode">
    <slot />
  </section>
</template>

<script setup lang="ts">
import { useCommonStore } from '~/store';
// import { useWindowScroll } from '@vueuse/core';

const indexStore = useCommonStore();
const nightBlock = ref<HTMLElement | null>(null);
const route = useRoute();
const { pageLoading } = storeToRefs(useCommonStore());
// const { y } = useWindowScroll(window);

defineOptions({ name: 'night-mode' });

function initializeObserver() {
	if (indexStore.observer) return; // Avoid reinitialization

	indexStore.observer = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				indexStore.nightBlocks.set(entry.target as HTMLElement, entry.isIntersecting);
			});
			updateNightMode(); // Update the global night mode state
		},
		{
			rootMargin: `-${indexStore.headerHeight / 2}px 0px -${screen.height}px 0px`,
			threshold: [0],
		}
	);
}

function addBlock(block: HTMLElement) {
	initializeObserver();
	if (indexStore.observer) indexStore.observer.observe(block);

	indexStore.nightBlocks.set(block, false);
}

function removeBlock(block: HTMLElement) {
	if (indexStore.observer) indexStore.observer.unobserve(block);

	if (indexStore.nightBlocks instanceof Map) {
		indexStore.nightBlocks.delete(block);
		updateNightMode();
	}
}

function updateNightMode() {
	const isNightModeActive = Array.from(indexStore.nightBlocks.values()).some(
		(isVisible) => isVisible
	);

	if (!pageLoading.value && isNightModeActive !== indexStore.isNightMode) {
		indexStore.isNightMode = isNightModeActive;

		if (indexStore.isNightMode) {
			indexStore.addNightTheme();
		} else {
			indexStore.removeNightTheme();
		}
	}
}

function disconnectObserver() {
	if (indexStore.observer) {
		indexStore.observer.disconnect();
		indexStore.observer = null;
	}
	indexStore.nightBlocks.clear();
	indexStore.isNightMode = false;
	indexStore.removeNightTheme(); // Reset to default state
}

// Watch for route changes (hash or full route)
watch(route, () => {
	setTimeout(() => {
		// updateNightMode(); // Re-check when route changes
	}, 200);
});

onMounted(() => {
	if (nightBlock.value) {
		addBlock(nightBlock.value);
	}
});

onBeforeUnmount(() => {
	if (nightBlock.value) {
		removeBlock(nightBlock.value);
	}
	// disconnectObserver();
});





// OLD LOGIC
// function changeNightTheme() {
//     if (!indexStore.nightBlocks.length) {
//         indexStore.removeNightTheme();
// 	    return;
//     }
//
//     if (!process.client) return;
//
//     for (let i = 0; i < indexStore.nightBlocks.length; i++) {
//         const block: HTMLElement = indexStore.nightBlocks[i];
//         const top = block.getBoundingClientRect().top || 0;
//         const bottom = block.getBoundingClientRect().top + block.offsetHeight;
//         console.log('top of night block', top);
//
//         if (top - indexStore.headerHeight / 2 <= 0 && bottom >= 0) {
//             indexStore.addNightTheme();
// 	        break;
//         } else {
//             indexStore.removeNightTheme();
//         }
//     }
// }
//
// onMounted(() => {
// 	if (nightBlock.value) {
// 		indexStore.nightBlocks.push(nightBlock.value);
//
// 		// без таймаута эта штука отрабатывает некорректно
// 		// и показывает неверное значение getBoundingClientRect
//
// 		// onComponentMounted();
// 		setTimeout(() => {
// 			changeNightTheme();
// 		}, 150);
// 	}
// });
//
// onBeforeUnmount(() => {
// 	if (nightBlock.value) {
//         const index = indexStore.nightBlocks.indexOf(nightBlock.value);
//         if (index > -1) {
//             indexStore.nightBlocks.splice(index, 1);
//         }
//
//         changeNightTheme();
//     }
// });
//
// watch(y, () => {
//     changeNightTheme();
// });
//
// const isModal = computed(() => {
//     return indexStore.isModal;
// });
//
// watch(isModal, () => {
//     setTimeout(() => {
//         changeNightTheme();
//     }, 50);
// });
</script>

<style lang="scss" src="~/assets/scss/components/ui/night-mode.scss"></style>
