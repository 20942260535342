<template>
    <header
        ref="header"
        :class="[
            'header',
            headerFixed ? 'fixed' : '',
            isModal ? 'fixed' : '',
            isModal ? 'header--modal' : '',
            nightTheme ? 'night-header' : ''
        ]"
    >
        <div class="header__container">
            <div class="header__row">
                <nuxt-link
                    class="header__logo"
                    to="/"
                    aria-label="Перейти на главную"
                    title="Digital Агентство - Makeit"
                >
                    <svg-icon name="logo" />
                </nuxt-link>
                <nav
                    class="header__nav header__nav--dp"
                >
                    <ul
                        class="header__nav-list"
                        itemscope
                        itemtype="http://schema.org/SiteNavigationElement"
                        vocab="https://schema.org/"
                        typeof="SiteNavigationElement"
                    >
                        <li
                            v-for="(link, i) in commonInfo.headerMenu"
                            :key="`nav-link--${i}`"
                            class="header__nav-item"
                            itemprop="name"
                            property="name"
                        >
                            <meta
                                itemprop="url"
                                property="url"
                                :content="`${BASE_URL}/${link.src}${link.hash?link.hash:''}`"
                            />
                            <Button
                                :el-type="'a'"
                                :class="[
									'header__nav-link',
									isActiveNavLink(link) ? 'active' : ''
								]"
                                :btn-style="'tetrary-root'"
                                :btn-size="'medium'"
                                :href="link.src"
                                :title="link.title"
                                @click="
                                    goToPage({
                                        path: link.src,
                                        hash: link.hash ? link.hash : '',
                                        prefetchLinks: false,
                                    })
                                "
                            >
                                {{ link.name }}
                            </Button>
                        </li>
                    </ul>
                </nav>
                <div class="header__contacts">
                    <div
                        v-if="commonInfo.tel"
                        class="header__contacts-item header__contacts-item--dp"
                    >
                        <a
                            class="header__contacts-link f-body"
                            :href="`tel:${formatTelNumber(
                                commonInfo.tel.value
                            )}`"
                            :title="commonInfo.tel.title"
                        >
                            {{ commonInfo.tel.value }}
                        </a>
                    </div>
                    <div
                        v-if="commonInfo.email"
                        class="header__contacts-item header__contacts-item--dp"
                    >
                        <a
                            class="header__contacts-link f-body"
                            :href="`mailto:${commonInfo.email.value}`"
                            :title="commonInfo.email.title"
                        >
                            {{ commonInfo.email.value }}
                        </a>
                    </div>
                    <div class="header__contacts-item">
                        <Button
                            v-if="!isModal"
                            class="header__contacts-btn"
                            :btn-size="'medium'"
                            :btn-style="'grad'"
                            :modal="ModalForm"
                        >
                            Начать проект
                        </Button>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="!isModal">
            <button
                class="header__menu-btn"
                :class="menuIsOpened ? 'active' : ''"
                type="button"
                aria-label="Открыть меню"
                @click="menuIsOpened = !menuIsOpened"
            >
                <svg-icon
                    v-if="!menuIsOpened"
                    class="icon--burger"
                    name="burger"
                />
                <svg-icon v-else class="icon--cross-big" name="cross-big" />
            </button>

            <Transition>
                <MobileMenu v-if="menuIsOpened" @close="menuIsOpened = false" />
            </Transition>
        </template>
    </header>
</template>

<script setup lang="ts">
import { useJsonld } from '#jsonld';
import { formatTelNumber } from '~/utils/formatTelNumber';
import { SCROLL_DEBOUNCE } from '~/utils/constants';
import Button from '~/components/ui/Button.vue';
import ModalForm from '~/components/modals/ModalForm.vue';
import MobileMenu from '~/components/layout/MobileMenu.vue';
import { useCommonStore } from '~/store';
import { useRouter, useRuntimeConfig } from 'nuxt/app';
import { useWindowScroll, watchDebounced } from '@vueuse/core';
import type { IMenuLink } from '~/types';

const props = defineProps({
    isModal: { type: Boolean, required: false, default: false },
});

const emit = defineEmits(['closeModal']);
const { y } = useWindowScroll(window);
const router = useRouter();
const route = useRoute();
const { addHeaderFixed, removeHeaderFixed, setHeaderHeight: setHeight } = useCommonStore();
const { commonInfo, nightTheme, headerFixed, headerHeight } = storeToRefs(useCommonStore());
const header = ref();
const menuIsOpened = ref(false);
const BASE_URL = useRuntimeConfig().public.baseUrl;

watchDebounced(y, (value) => {
    if (
        value >= headerHeight.value ||
        document.body.classList.contains('modal-open')
    ) {
        addHeaderFixed();
    } else {
        removeHeaderFixed();
    }
}, { debounce: SCROLL_DEBOUNCE, maxWait: SCROLL_DEBOUNCE * 2 });

const isActiveNavLink = (link: IMenuLink) => {
    const currentRoute = link.hash ? route.hash : route.path;
    if (
        (link.hash
            ? currentRoute === link.hash
            : currentRoute.split('/?')[0].split('?')[0].split('#')[0] === link.src)
        ) {
        return true;
    }

    return false;
};

function setHeaderHeight() {
	setHeight(header.value.offsetHeight);

    if (
        window.pageYOffset >= headerHeight.value ||
        document.body.classList.contains('modal-open')
    ) {
        addHeaderFixed();
    } else {
        removeHeaderFixed();
    }
}

onMounted(() => {
    setHeaderHeight();

    if (process.client) {
        window.addEventListener('resize', setHeaderHeight);
    }
});

onUnmounted(() => {
    if (process.client) {
        window.removeEventListener('resize', setHeaderHeight);
    }
});

interface IPageLink {
    path: string;
    hash?: string;
    prefetchLinks?: boolean;
}

const goToPage = (params: IPageLink | string) => {
    menuIsOpened.value = false;
    router.push(params);
    if (props.isModal) emit('closeModal');
};

// @ts-ignore
useJsonld(() => ({
	'@context': 'https://schema.org',
	'@type': 'Organization',
	'url': process.client ? window.location.host : '',
	'logo': '/images/logo.svg'
}));
</script>

<style
    lang="scss"
    src="~/assets/scss/components/layout/header.scss"
    scoped
></style>
