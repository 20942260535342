<template>
    <div
        :class="[
            'form-file-input',
            night ? 'form-file-input--night' : '',
            error ? 'input-error' : ''
        ]"
    >
        <Button
            for="file-input"
            :el-type="'label'"
            class="form-file-input__button"
            :btn-style="isModal ? 'secondary-night' : 'secondary'"
            :icon-name="'plus'"
            @click="loadFile"
        >
            {{ label }}
        </Button>

        <div v-if="files" class="form-file-input__results">
            <Button
                v-for="file in Object.values(files)"
                :key="file.name"
                class="form-file-input__result"
                :btn-style="isModal ? 'tetrary-night' : 'tetrary'"
                :icon-name="'cross-small'"
                @click="() => deleteFile(file)"
            >
                <span>{{ file.name }}</span>
            </Button>
        </div>

        <input
	        class="hidden-input"
            type="file"
            :name="name"
            id="file-input"
            ref="inputfile"
            @change="onFileChanged($event)"
            multiple
        />
        <div class="form-error f-caption">{{ error }}</div>
    </div>
</template>

<script setup lang="ts">
import Button from '~/components/ui/Button.vue';

const inputfile = ref();
const files = ref<FileList | null>();

defineProps({
    name: { type: String, required: false, readonly: true },
    isModal: { type: Boolean, default: false, readonly: true },
    label: { type: String, readonly: true },
    night: { type: Boolean, readonly: true },
    error: { type: String, readonly: true },
});

const emit = defineEmits(['change']);

const loadFile = () => {
    inputfile.value.click();
};

function onFileChanged($event: Event) {
    const target = $event.target as HTMLInputElement;
    if (target && target.files) {
        files.value = target.files;

        emit('change', files.value);
    }
}

function deleteFile(data: File) {
    // let newFileList = new ClipboardEvent('').clipboardData || new DataTransfer();
    let newFileList = [...files.value];

    const index = newFileList.findIndex((el) => el.name === data.name);
    newFileList.splice(index, 1);

    // console.log('deleting file', data.name);
    //
    // for (let i = 0; i < files.value.length; i++) {
    // 	let file = files.value[i];
    // 	if (data.name !== file.name) {
    // 		console.log('adding file', file.name);
    // 		newFileList.items.add(file);
    // 	}
    // }

    // console.log('index', newFileList);
    files.value = newFileList;

    emit('change', files.value);
}
</script>

<style
    lang="scss"
    src="~/assets/scss/components/ui/form-file-input.scss"
    scoped
></style>
