<template>
    <Modal
        :options="modalProps"
        :modal-class="'request absolute inset-0 h-full overflow-auto'"
        :btn-style-class="'primary-root'"
        @close="emit('confirm')"
    >
        <NightMode class="night-mode--modal">
            <Header :is-modal="true" @closeModal="emit('confirm')" />

            <div class="modal__wrap">
                <FeedbackForm :is-modal="true" :is-night-theme="true" data-aos="fade-up" />
            </div>
        </NightMode>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '~/components/ui/Modal.vue';
import FeedbackForm from '~/components/forms/FeedbackForm.vue';
import NightMode from '~/components/ui/NightMode.vue';
import Header from '~/components/layout/Header.vue';
import type { ISliderProps, IModalProps } from '~/types';
const emit = defineEmits(['confirm']);

const modalProps: IModalProps = {
    teleportTo: 'body',
    contentTransition: 'vfm-fade',
    overflowTransition: 'vfm-fade',
    escToClose: true,
    clickToClose: true,
    class: 'modal-wrap',
    contentClass: 'modal-content',
    overlayClass: 'modal-overlay',
};
</script>

<style lang="scss" src="~/assets/scss/components/ui/modal.scss" scoped></style>
