
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as icons_45pageCDr2G7nxVCMYmKb4R9_qbff37mz3226tyrAu0Xp22wwMeta } from "/makeitapp/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as _1cbitrixTQZyuAZH91JE0VwHthKzAtZVs6WE4OsYZpVYKYzb_45RgMeta } from "/makeitapp/pages/1cbitrix.vue?macro=true";
import { default as bitrix24aLWA5WycpmZQ4u2_r7wLPV1_45LmLnMssor8NuskGuF4oMeta } from "/makeitapp/pages/bitrix24.vue?macro=true";
import { default as _91id_93uaDvD6o0wM9R_45BUlmHMUX6vA9J2oVHDav_45o8lVXYqTAMeta } from "/makeitapp/pages/cases/[id].vue?macro=true";
import { default as indexono8ts4YlRBpD6Jef_456YJZq_V1n7CIb7Bi3HnkaSki0Meta } from "/makeitapp/pages/cases/index.vue?macro=true";
import { default as companyu22HTDPvTgXEW7dDTWZyz7_45u5PEub5k27aZWqShBJ3QMeta } from "/makeitapp/pages/company.vue?macro=true";
import { default as contacts8DuF9fVRKTLtqjVN9ni1cqAbnAUhX47bExYhbfocGUYMeta } from "/makeitapp/pages/contacts.vue?macro=true";
import { default as indexBGHC_ISM8BZNw1CmpkHIfj5B_45flfZfzMqQR7tsfSBWgMeta } from "/makeitapp/pages/index.vue?macro=true";
import { default as policyrU7614yEGAxwEejqpxdzfd_cQxNyMApvl8SGDfWe1BMMeta } from "/makeitapp/pages/policy.vue?macro=true";
import { default as requisitesZmN8i_45W17O7_ST6G6iFCh9t2QVFso9i9aKK9aiPh7vwMeta } from "/makeitapp/pages/requisites.vue?macro=true";
import { default as sitemapbb3L8H27h1vi6FMOiAoP5o8FeoZzqjT3i0Y93meB_ggMeta } from "/makeitapp/pages/sitemap.vue?macro=true";
import { default as test_45spline3lVpa2uhZy6J40l1M2T_eDPyg7ppl1t2t2ggTsdOzeQMeta } from "/makeitapp/pages/test-spline.vue?macro=true";
import { default as uiqIY3Yw64HgPIc_2u10tkaFEC1QN_1yxcLyYEF5Ua3PIMeta } from "/makeitapp/pages/ui.vue?macro=true";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/makeitapp/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue")
  },
  {
    name: "1cbitrix",
    path: "/1cbitrix",
    component: () => import("/makeitapp/pages/1cbitrix.vue")
  },
  {
    name: "bitrix24",
    path: "/bitrix24",
    component: () => import("/makeitapp/pages/bitrix24.vue")
  },
  {
    name: "cases-id",
    path: "/cases/:id()",
    component: () => import("/makeitapp/pages/cases/[id].vue")
  },
  {
    name: "cases",
    path: "/cases",
    component: () => import("/makeitapp/pages/cases/index.vue")
  },
  {
    name: "company",
    path: "/company",
    component: () => import("/makeitapp/pages/company.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/makeitapp/pages/contacts.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/makeitapp/pages/index.vue")
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/makeitapp/pages/policy.vue")
  },
  {
    name: "requisites",
    path: "/requisites",
    component: () => import("/makeitapp/pages/requisites.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/makeitapp/pages/sitemap.vue")
  },
  {
    name: "test-spline",
    path: "/test-spline",
    component: () => import("/makeitapp/pages/test-spline.vue")
  },
  {
    name: "ui",
    path: "/ui",
    component: () => import("/makeitapp/pages/ui.vue")
  }
]