import { IS_DEV } from '~/utils/constants';
import { defineStore } from 'pinia';

import type {
    ICommonInfo,
    IMetaInfo,
} from '~/types';

export const useCommonStore = defineStore('index', {
    state: () => ({
        headerFixed: false as boolean,
        headerHeight: 0 as number,
        footerBackground: '' as string,
        scrollBarWidth: 0 as number,
        nightTheme: false as boolean,
        nightBlocks: new Map<HTMLElement, boolean>(),
        observer: null as IntersectionObserver | null,
        isModal: false as boolean,
        splines: {
            company: false as boolean,
            contacts: false as boolean,
            services: false as boolean,
            banner: false as boolean,
        } as { [key: string]: boolean },
        commonInfo: {} as ICommonInfo,
        mockCommonInfo: {
            email: {
                title: 'Email',
                value: 'hello@makeit.ru',
            },
            tel: {
                title: 'Telephone',
                value: '8 (812) 507-98-33',
            },
            address: 'Санкт-Петербург, пр. Большой П.С. 100',
            presentationUrl: '/files/test-file-1.docx',
            showreelPreview: '/videos/showreel.mp4',
            showreelImg: '/images/showreel-btn.gif',
            coordinates: ['59.962246', '30.319758'],
            headerMenu: [
                {
                    id: '33',
                    name: 'Услуги',
                    src: '/',
                    hash: '#services-page',
                },
                {
                    id: '34',
                    name: 'Кейсы',
                    src: '/cases',
                    hash: null,
                },
                {
                    id: '35',
                    name: 'Компания',
                    src: '/company',
                    hash: null,
                },
                {
                    id: '36',
                    name: 'Контакты',
                    src: '/contacts',
                    hash: null,
                }
            ],
            footerMenu: [
                {
                    id: '37',
                    name: 'Реквизиты',
                    src: '/requisites',
                    hash: null,
                },
                {
                    id: '38',
                    name: '1C Битрикс',
                    src: '/1cbitrix',
                    hash: null,
                },
                {
                    id: '39',
                    name: 'Битрикс24',
                    src: '/bitrix24',
                    hash: null,
                }
            ],
            socials: [
                {
                    id: '41',
                    name: 'Youtube',
                    src: 'https://www.youtube.com/@makeit_digital',
                    icon: '/images/socials/youtube.svg',
                    iconDarkTheme: '/images/socials/youtube-dark-theme.svg',
                },
                {
                    id: '42',
                    name: 'ВКонтакте',
                    src: 'https://vk.com/makeit_da',
                    icon: '/images/socials/vk.svg',
                    iconDarkTheme: '/images/socials/vk-dark-theme.svg',
                }
            ]
        } as ICommonInfo,
        metaInfo: [] as IMetaInfo[],
        mockMetaInfo: [
            {
                breadcrumbs: 'Главная',
                url: 'index',
                title: 'Главная MakeIt',
                h1: 'Главная MakeIt заголовок',
                description: 'Главная MakeIt описание',
                keywords: 'Главная MakeIt'
            },
            {
                breadcrumbs: 'Контакты',
                url: 'contacts',
                title: 'Контакты',
                h1: 'Контакты',
                description: 'Возьмем ваш проект в реализацию с любовью и вниманием',
                keywords: ''
            },
            {
                breadcrumbs: 'Компания',
                url: 'company',
                title: 'Компания',
                h1: 'Компания',
                description: 'Makeit — это агентство digital решений, сочетающее в себе прокаченные компетенции в дизайне, разработке и обслуживании. Мы гордимся нашими уникальными знаниями и опытом, которые применяем для решения задач бизнеса. Наша команда не только создает проекты, но и обеспечивает их сопровождение с целью достижения запланированных результатов.',
                keywords: ''
            },
            {
                breadcrumbs: 'Кейсы',
                url: 'cases',
                title: 'Кейсы',
                h1: 'Кейсы',
                description: 'Воплощаем задачи бизнеса в дизайне и технологиях',
                keywords: ''
            },
            {
                breadcrumbs: 'Реквизиты',
                url: 'requisites',
                title: 'Реквизиты',
                h1: 'Реквизиты',
                description: '',
                keywords: ''
            },
            {
                breadcrumbs: '1С Битрикс',
                url: '1cbitrix',
                title: '1С-Битрикс',
                h1: '1С-Битрикс',
                description: '',
                keywords: ''
            },
            {
                breadcrumbs: 'Битрикс24',
                url: 'bitrix24',
                title: 'Внедрение решений на базе «Битрикс24»',
                h1: 'Внедрение решений на базе «Битрикс24»',
                description: '',
                keywords: ''
            },
            {
                breadcrumbs: 'Политика конфиденциальности',
                url: 'policy',
                title: 'Заголовок политики',
                h1: 'Политика',
                description: 'Мета описание политики',
                keywords: ''
            }
        ] as IMetaInfo[],
        sitemapInfo: [],
        currentPage: {} as IMetaInfo,
        currentSpline: {} as string,
        isLoading: false as boolean,
        pageLoading: true as boolean,
    }),
    actions: {
        setLoading(value: boolean) {
            this.isLoading = value;
        },

        setPage(value: IMetaInfo) {
            this.currentPage = value;
        },

        addHeaderFixed() {
            this.headerFixed = true;
        },

        setHeaderHeight(value: number) {
            this.headerHeight = value;

            document.documentElement.style.setProperty(
                `--header-height`,
                ``.concat(value.toString(), `px`)
            );
        },

        setScrollBarWidth(value: number) {
            this.scrollBarWidth = value;
        },

        removeHeaderFixed() {
            this.headerFixed = false;
        },

        setFooterBg(bg: string) {
            this.footerBackground = bg;
        },


        openModal(): void {
            this.isModal = true;
        },

        closeModal(): void {
            this.isModal = false;
        },

        // setSpline(page: string, bool: boolean) {
        //     this.splines[page] = bool;
        // },

        setCommonInfo(info: ICommonInfo) {
            this.commonInfo = info;
        },

        changeNightTheme(theme: boolean) {
            this.nightTheme = theme;
        },

        addNightTheme() {
            document.body.classList.add(`night-theme`);
            document.documentElement.style.setProperty(`--color`, `255, 255, 255`);
            document.documentElement.style.setProperty(`--bg`, `0, 0, 0`);
            this.changeNightTheme(true);
        },

        removeNightTheme() {
            document.body.classList.remove(`night-theme`);
            document.documentElement.style.setProperty(`--color`, `0, 0, 0`);
            document.documentElement.style.setProperty(`--bg`, `255, 255, 255`);
            this.changeNightTheme(false);
        },

        async fetchCommonInfo(): Promise<ICommonInfo | null> {
            if (IS_DEV) {
                this.setCommonInfo(this.mockCommonInfo);
                return this.mockCommonInfo;
            } else {
                this.setLoading(true);

                try {
                    const data: ICommonInfo = await $fetch(
                        `/api/common`
                    );

                    if (data) {
                        this.setCommonInfo(data);
                        return data;
                    }
                    return null;
                } catch (error) {
                    console.error('ERROR on fetchCommonInfo: ', error);
                    return null;
                } finally {
                    this.setLoading(false);
                }
            }
        },

        setMetaInfo(info: IMetaInfo[]) {
            this.metaInfo = info;
        },

        setSitemapInfo(info) {
            this.sitemapInfo = info;
        },

        async fetchMetaInfo(): Promise<IMetaInfo[] | null> {
            if (IS_DEV) {
                this.setMetaInfo(this.mockMetaInfo);
                return this.mockMetaInfo;
            } else {
                this.setLoading(true);

                try {
                    const data : IMetaInfo[] = await $fetch(
                        `/api/meta`
                    );

                    if (data) {
                        this.setMetaInfo(data);
                        return data;
                    }

                    return null;
                } catch (error) {
                    console.error('ERROR on fetchMetaInfo: ', error);
                    return null;
                } finally {
                    this.setLoading(false)
                }
            }
        },

        async fetchSitemap() {
            this.setLoading(true);

            try {
                const data = await $fetch(
                    `/api/meta/sitemap`
                );

                if (data) {
                    this.setSitemapInfo(data);
                }
            } catch (error) {
                console.error('ERROR on fetchMetaInfo: ', error);
            } finally {
                this.setLoading(false);
            }
        },
    },
});
