export const TEL_MASK: string = '+7 (###) ###-##-##';
export const MAX_FILE_SIZE: number = 102400; // 102400

export const CASES_PAGE_LIMIT: number = 12;
export const CASES_MAIN_PAGE_LIMIT: number = 5;
export const CASES_ADD_PAGE_LIMIT: number = 3;

export const SCROLL_DEBOUNCE: number = 50;
export const FILE_TYPES: string[] = [
    'jpg',
    'gif',
    'png',
    'jpeg',
    'svg',
    'webp',
];
export const FORM_VALIDATION_ERROR_MES = {
    REQUIRED: 'Ошибка: поле обязательно для заполнения',
    EMAIL_FORMAT: 'Ошибка: e-mail неправильного формата',
    TEL_MIN_LENGTH: 'Ошибка: телефон заполнен неполностью',
    FILE_MAX_SIZE: 'Ошибка: прикрепляемый файл слишком большой по размеру',
    FILE_FORMAT: 'Ошибка: неверный формат файла',
};
export const IS_DEV: boolean = false;

// export const DEV_BASE_URL: string = 'https://0bd24a47-ca5a-4019-b675-729015cf7204.mock.pstmn.io';
// const DEV_BASE_URL: string = 'https://viktordronboy.stage-backend.makeit.makeit-dev.ru';
// const PROD_BASE_URL: string = 'https://backoffice.makeit.ru';

export const BASE_URL = process.env.API_URL;

// export const BASE_URL: string = process.env.NODE_ENV === 'development' ? DEV_BASE_URL : PROD_BASE_URL;

export const API_KEY: string =
    'PMAK-65522beb34a8cb003f59eb26-0a0a1053ee7b3b57d53dbb0dc0c33cc36e';
// export const YMAPS_API_KEY: string = 'a2ef3d80-6860-4877-b283-cc621bdb2986';
export const YMAPS_API_KEY: string = '0375a0ba-641d-41a5-8a57-894577a863e7';

export const TIMEOUT: { [key: string]: number; } = {
    SHORT: 20,
    MEDIUM: 100,
    DEFAULT: 300,
    LONG: 500,
};
