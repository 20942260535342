<template>
    <div class="page page--vh page--night-theme">
        <Header />

        <div class="container--max container--vh">
            <Video
                class="video--bg"
                :src="'/videos/404.mp4'"
                :playsinline="true"
                :autoplay="true"
                :muted="true"
                :loop="true"
                :fluid="true"
            />
        </div>

        <main class="main">
            <NightMode class="section section--top">
                <div class="container">
                    <div
                        class="p-t-145-lg p-t-96-dp p-t-70-tab p-t-70-mob p-b-145-lg p-b-96-dp p-b-70-tab p-b-64-mob"
                    >
                        <h1 class="f-title m-b-40">404</h1>
                        <div class="f-lead m-b-24 c-white-60">
                            Упс...Похоже, такая страница не существует
                        </div>
                        <div class="f-lead m-b-24 c-white-60">
                            Попробуйте начать с
                            <nuxt-link class="link" to="/">
                                главной страницы
                            </nuxt-link>
                        </div>
                    </div>
                </div>
            </NightMode>
        </main>

        <Footer />

        <ModalsContainer />
    </div>
</template>

<script setup lang="ts">
import NightMode from '~/components/ui/NightMode.vue';
import Header from '~/components/layout/Header.vue';
import Footer from '~/components/layout/Footer.vue';
import Video from '~/components/ui/Video.vue';
import { ModalsContainer } from 'vue-final-modal';
import { useCommonStore } from '~/store';
import type { IMetaInfo } from '~/types';

const commonStore = useCommonStore();
const route = useRoute();
const url = useRequestURL();
const page = computed(() => {
    return commonStore.metaInfo.find(
        (el: IMetaInfo) => el.url === '404'
    );
});

await useAsyncData(
	async () => Promise.all([
        commonStore.fetchCommonInfo(),
        commonStore.fetchMetaInfo()
	])
);

// onBeforeMount(() => {
//     commonStore.addNightTheme();
// });

useSeoMeta({
    title: page.value?.title,
    description: page.value?.description,
    keywords: page.value?.keywords,
    ogTitle: page.value?.title,
    ogDescription: page.value?.description,
    ogUrl: `${url.protocol}//${url.host}${route.path}`,
});

useHead(() => ({
    link: [{ rel: 'canonical', href: `${url.protocol}//${url.host}${route.path}` }],
}));

defineOptions({ name: 'ErrorPage' });
</script>

<style lang="scss" src="~/assets/scss/global.scss"></style>
<style lang="scss" src="~/assets/scss/pages/section.scss" scoped></style>
