<template>
	<Transition name="fade">
		<Button
			v-if="isVisible"
			:class="'button-up'"
			:btn-style="'primary-root'"
			:btn-size="'medium'"
			:btn-form="'round-horizontal'"
			:icon-name="'arrow-up'"
			@click="onButtonUpClick"
		/>
	</Transition>
</template>

<script setup lang="ts">
import Button from '~/components/ui/Button.vue';
import { useWindowScroll, watchDebounced } from '@vueuse/core';
import { SCROLL_DEBOUNCE } from '~/utils/constants';

const isVisible = ref(false);
const { y } = useWindowScroll(window);

watchDebounced(y, (value) => {
	if (value >= screen.height) {
		isVisible.value = true;
	} else {
		isVisible.value = false;
	}
}, { debounce: SCROLL_DEBOUNCE, maxWait: SCROLL_DEBOUNCE * 2 });

function onButtonUpClick() {
	window.scroll({
		top: 0,
		left: 0,
		behavior: 'smooth'
	});
}
</script>

<style scoped src="~/assets/scss/components/ui/button-up.scss" lang="scss"></style>
