<template>
    <div
        :class="[
            'form-input',
            night ? 'form-input--night' : '',
            error ? 'input-error' : '',
        ]"
    >
        <input
            v-if="type === 'tel'"
            v-mask="TEL_MASK"
            :type="type"
            :name="name"
            :placeholder="label"
            :value="modelValue"
            @input="onInput"
            @blur="onBlur"
        />
        <input
            v-else
            :type="type"
            :name="name"
            :placeholder="label"
            :value="modelValue"
            @input="onInput"
            @blur="onBlur"
        />

        <div class="form-error f-caption">{{ error }}</div>
    </div>
</template>

<script setup lang="ts">
import { TEL_MASK } from '~/utils/constants';

defineProps({
    type: { type: String, default: 'text', required: false, readonly: true },
    name: { type: String, required: false, readonly: true },
    label: { type: String, required: false, readonly: true },
    error: { type: String, readonly: true },
    night: { type: Boolean, readonly: true },
    modelValue: { type: String },
});

const emit = defineEmits(['update:modelValue', 'blur']);
const onInput = (e) => emit('update:modelValue', e.target.value);
const onBlur = () => emit('blur');
</script>

<style
    lang="scss"
    src="~/assets/scss/components/ui/form-input.scss"
    scoped
></style>
