<template>
    <div class="mobile-menu">
        <div class="mobile-menu__links m-b-16">
            <Button
                v-for="(link, i) in indexStore.commonInfo.headerMenu"
                :key="`nav-link--${i}`"
                :el-type="'a'"
                :btn-size="'medium'"
                :class="[
					'mobile-menu__btn',
					(link.hash ? currentRoute === link.hash : currentRoute === link.src) ? 'active' : ''
				]"
                :href="link.src"
                :title="link.title"
                @click="
                    emit('close');
                    $router.push({
                        path: link.src,
                        hash: link.hash ? link.hash : '',
                        prefetchLinks: false,
                    });
                "
            >
                {{ link.name }}
            </Button>
        </div>

        <div class="mobile-menu__bottom">
            <Button
                :btn-size="'medium'"
                :btn-style="'grad'"
                class="m-b-16 w-100"
                :modal="ModalForm"
            >
                Начать проект
            </Button>
            <div
                v-if="indexStore.commonInfo.tel || indexStore.commonInfo.email"
                class="mobile-menu__flex m-b-16"
            >
                <div
                    v-if="indexStore.commonInfo.tel"
                    class="mobile-menu__flex-item"
                >
                    <a
                        class="mobile-menu__link f-caption c-white-60"
                        :href="`tel:${formatTelNumber(
                            indexStore.commonInfo.tel.value
                        )}`"
                        :title="indexStore.commonInfo.tel.title"
                    >
                        {{ indexStore.commonInfo.tel.value }}
                    </a>
                </div>
                <div
                    v-if="indexStore.commonInfo.email"
                    class="mobile-menu__flex-item"
                >
                    <a
                        class="mobile-menu__link f-caption c-white-60"
                        :href="`mailto:${indexStore.commonInfo.email.value}`"
                        :title="indexStore.commonInfo.email.title"
                    >
                        {{ indexStore.commonInfo.email.value }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { formatTelNumber } from '~/utils/formatTelNumber';
import ModalForm from '~/components/modals/ModalForm.vue';
import Button from '~/components/ui/Button.vue';
import { useCommonStore } from '~/store';

const indexStore = useCommonStore();
const emit = defineEmits(['close']);
const route = useRoute();
const currentRoute = computed(() => {
	return route.hash ? route.hash : route.href;
});
</script>

<style
    lang="scss"
    src="~/assets/scss/components/layout/mobile-menu.scss"
    scoped
></style>
