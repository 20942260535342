<template>
    <component
        :is="elType"
        :class="[
            'button',
            btnStyle ? `button--${btnStyle}` : btnStyle,
            btnSize ? `button--${btnSize}` : '',
            btnForm ? `button--${btnForm}` : '',
            btnPosition ? `button--${btnPosition}` : '',
            { 'button--loading': loading },
            { 'button--disabled': disabled },
        ]"
        :type="elType === 'button' ? btnType : null"
        @click.prevent="onClick"
    >
        <slot />

        <span v-if="btnContent" class="button__span">
            {{ btnContent }}
        </span>

        <svg-icon
            v-if="iconName"
            :class="`icon--${iconName}`"
            :name="iconName"
        />

        <NuxtImg
            v-if="imgBtn"
            class="img"
            :src="imgBtn.src"
            :alt="imgBtn.alt"
            :title="imgBtn.title"
            loading="lazy"
            format="webp"
            placeholder
        />

        <ModalsContainer v-if="modal" />
    </component>
</template>

<script setup lang="ts">
import { ModalsContainer, useModal } from 'vue-final-modal';
import { useCommonStore } from '~/store';
import { type Component } from 'vue';

const props = defineProps({
    elType: { type: String, default: 'button', required: false, readonly: true },
    btnType: { type: String, default: 'button', required: false, readonly: true },
    btnSize: { type: String, readonly: true },
    btnStyle: { type: String, default: 'primary', readonly: true },
    btnForm: { type: String, default: '', readonly: true },
    btnPosition: { type: String, default: '', readonly: true },
    iconName: { type: String, default: undefined, readonly: true },
    imgBtn: { type: Object as PropType<{ src: string; title: string; alt: string; }>, default: undefined, readonly: true },
    btnContent: { type: String, default: undefined, readonly: true },
    loading: { type: Boolean, default: false, readonly: true },
    disabled: { type: Boolean, default: false, readonly: true },
    downloadLink: { type: String, readonly: true },
    modal: { required: false, readonly: true },
    isShowreel: { type: Boolean, default: false, required: false, readonly: true },
    videoSrc: { type: String, default: '', required: false, readonly: true },
	modalContent: { type: Object, default: {}, required: false, readonly: true },
	offerId: { type: String, required: false, default: '', readonly: true },
});

const emit = defineEmits(['click']);

// ссылка на скачивание
const downloadHandler = (url) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};


const onClick = (event: Event): void => {
    if (props.disabled || props.loading) return;

	if (props.downloadLink) {
        downloadHandler(props.downloadLink);
    }

    if (props.modal) {
        if (props.isShowreel) {
	        location.hash = '#showreel';
        } else {
	        const modalParams = {
                component: props.modal,
                attrs: {
                    videoSrc: props.videoSrc,
                    modalContent: props.modalContent,
                    onConfirm(): void {
                        if (props.isShowreel) {
	                        history.replaceState(null, '', ' ');
                        }
                        close();
                    },
                },
            };
            if (props.offerId) {
                modalParams.attrs.offerId = props.offerId;
            }
            // ссылка на открытие модалки
            const { open, close } = useModal(modalParams);
            open();
        }
    }

	emit('click', event);
};
</script>

<style lang="scss" src="~/assets/scss/components/ui/button.scss" scoped></style>
