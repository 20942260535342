import AOS from 'aos';
import 'aos/dist/aos.css';

export default defineNuxtPlugin((nuxtApp) => {
    // nuxtApp.AOS = AOS.init({
    //     once: true,
    // });

    nuxtApp.hook('page:finish', () => {
        if (typeof window !== `undefined` && process.client) {
            if (!AOS.initialized) {
                AOS.init({ once: true, offset: 0 });
                AOS.initialized = true;
            } else {
                AOS.refresh();
            }

            window.onresize = function () {
                AOS.refresh();
            }
        }
    });

    // nuxtApp.vueApp.directive('aos', {
    //     mounted(el, binding) {
    //         // if (typeof window !== 'undefined' && !AOS.initialized) {
    //         //     AOS.init({ once: true });
    //         //     AOS.initialized = true;
    //         // }
    //
    //         if (typeof window !== 'undefined' && process.client) {
    //             if (typeof binding.value === 'string') {
    //                 el.setAttribute('data-aos', binding.value)
    //             } else if (typeof binding.value === 'object') {
    //                 for (const [key, value] of Object.entries(binding.value)) {
    //                     if (key !== 'name') {
    //                         el.setAttribute(`data-aos-${key}`, value)
    //                     }
    //
    //                     el.setAttribute('data-aos', binding.value.name)
    //                 }
    //             }
    //
    //             setTimeout(() => {
    //                 AOS.init({ once: true, offset: 0 });
    //                 AOS.initialized = true;
    //                 AOS.refresh();
    //             }, 100);
    //         }
    //     },
    // });
});
