import { IS_DEV } from '~/utils/constants';
import { defineStore } from 'pinia';
import type { ICaseDetail } from '~/types';

export const useCaseDetailStore = defineStore('case-detail', {
    state: () => ({
        caseDetail: {} as ICaseDetail | null,
        mockCaseDetail: {
            id: 'reishi',
            type: 4,
            common: {
                title: '«BTELL» — сервис по продаже сим-карт для агентов клиентов',
                siteLink: 'btell.ru',
                banner: '/images/cases/case-banner-1.png',
                videoBanner: '/videos/video-case.mp4',
                video: '/videos/showreel.mp4',
                dark_theme: {
                    background: '#F6B921',
                    collor: '',
                    night_theme: false,
                    darkThemeCaseDetail: false,
                    backgroundColorOtherCasesBlock: '',
                    textColorOtherCasesBlock: '',
                    feedbackFormBackgroundColor: '',
                    darkThemeFeedbackFormBlock: false,
                },
                seo: {
                    title: '',
                    h1: '',
                    description: '',
                    keywords: '',
                    pictureTitle: '',
                    pictureAlt: '',
                }
            },
            client: {
                name: 'Ф-Плюс Мобайл',
                title: 'Заголовок - о клиенте',
                department: 'Ритейл',
                description:
                    'Сеть магазинов обуви для детей и подростков основанная в 2005 году. Благодаря опытным сотрудникам, в магазины поступают лучшие модели обувного рынка. В&nbsp;магазинах представлен широчайший ассортимент.',
                img: {
                    src: '/images/cases/case-logo.svg',
                    seo: {
                        pictureTitle: '',
                        pictureAlt: '',
                    }
                }
            },
            blocks: [
                {
                    type: 'tasks',
                    content: {
                        title: 'Заголовок - задачи',
                        description:
                            'Сеть магазинов обуви для детей и подростков основанная в 2005 году. Благодаря опытным сотрудникам, в магазины поступают лучшие модели обувного рынка. В&nbsp;магазинах представлен широчайший ассортимент.',
                        array: [
                            { id: 0, text: 'Создать удобную структуру разделов, чтобы разместить действительно много информации о больнице, ее специалистах и услугах.' },
                            { id: 1,text: 'Создать удобную структуру разделов, чтобы разместить действительно много информации о больнице, ее специалистах и услугах.' },
                            { id: 2,text: 'Создать удобную структуру разделов, чтобы разместить действительно много информации о больнице, ее специалистах и услугах.' },
                        ],
                    },
                    background: '',
                    collor: '',
                    night_theme: false,
                },
                {
                    type: 'technologies',
                    content: {
                        title: 'Заголовок - технологии',
                        array: ['PHP', '1С-БИТРИКС', 'LARAVEL', 'SYMFONY', 'YII', 'ZEND', 'JAVA', 'ASP.NET (C#)', 'GO', 'PYTHON', 'NODE.JS', 'RUBY'],
                    },
                    background: '',
                    collor: '',
                    night_theme: false,
                },
                {
                    type: 'photos',
                    content: {
                        title: '',
                        description: '',
                        photos: [
                            {
                                src: '/images/cases/case-detail-1.png',
                                caption: 'Мобильная версия',
                            },
                            {
                                src: '/images/cases/case-detail-2.png',
                                caption:
                                    'Вся информация про абонентов доступна на любом устройстве и разрешении',
                            },
                            {
                                src: '/images/cases/case-detail-3.png',
                                caption: 'Понятные формы, чётенькие кнопки, все дела',
                            },
                            {
                                src: '/images/cases/case-detail-4.png',
                                caption:
                                    'Заказ сим карт — один из основных сценариев. Поэтому тут пиздатый интерфейс и все дела.',
                            },
                        ]
                    },
                    background: '',
                    collor: '',
                    night_theme: false,
                },
                {
                    type: 'slider',
                    content: {
                        title: 'Просто слайдер с экранами для понимания проекта',
                        description: 'Описание',
                        img: [
                            { src: '/images/cases/case-banner-2.png', caption: '' },
                            { src: '/images/cases/case-banner-2.png', caption: '' },
                            { src: '/images/cases/case-banner-2.png', caption: '' },

                        ],
                    },
                    background: '',
                    collor: '',
                    night_theme: false,
                },
                {
                    type: 'photos',
                    content: {
                        title: '',
                        description: '',
                        photos: [
                            {
                                src: '/images/cases/case-detail-1.png',
                                caption: 'Мобильная версия',
                            },
                            {
                                src: '/images/cases/case-detail-2.png',
                                caption:
                                    'Вся информация про абонентов доступна на любом устройстве и разрешении',
                            },
                            {
                                src: '/images/cases/case-detail-3.png',
                                caption: 'Понятные формы, чётенькие кнопки, все дела',
                            },
                            {
                                src: '/images/cases/case-detail-4.png',
                                caption:
                                    'Заказ сим карт — один из основных сценариев. Поэтому тут пиздатый интерфейс и все дела.',
                            },
                        ],
                        photo_grid: '1-1-1-1-small'
                    },
                    background: '',
                    collor: '',
                    night_theme: false,
                },
                {
                    type: 'photos',
                    content: {
                        title: '',
                        description: '',
                        photos: [
                            {
                                src: '/images/cases/case-detail-1.png',
                                caption: 'Мобильная версия',
                            },
                            {
                                src: '/images/cases/case-detail-2.png',
                                caption:
                                    'Вся информация про абонентов доступна на любом устройстве и разрешении',
                            },
                            {
                                src: '/images/cases/case-detail-3.png',
                                caption: 'Понятные формы, чётенькие кнопки, все дела',
                            },
                        ],
                        photo_grid: '1-2-2-2'
                    },
                    background: '',
                    collor: '',
                    night_theme: false,
                },
                // {
                //     type: 'performers',
                //     content: {
                //         title: 'Заголовок - над проектом работали',
                //         array: [
                //             { department: 'Дизайнер', names: ['Полина Шершнева'] },
                //             { department: 'Арт-директор', names: ['Аша Саакян'] },
                //             { department: 'Менеджер', names: ['Аша Саакян'] },
                //             { department: 'Бета-тестер', names: ['Аша Саакян'] },
                //             {
                //                 department: 'Технологи',
                //                 names: [
                //                     'Аша Саакян',
                //                     'Константин Томашевич',
                //                     'Денис Гаврилов',
                //                 ],
                //             },
                //             { department: 'Программист', names: ['Аша Саакян'] },
                //         ],
                //     },
                //     background: '',
                //     collor: '',
                //     night_theme: false,
                // },
                // {
                //     type: 'text',
                //     content: {
                //         title: "Кто наш клиент?",
                //         description: "Заказчик - компания Aurum Queen, в портфеле которой множество проектов, связанных со здоровьем и красотой. В продаже — средства для ухода за лицом и телом, корейская косметика, различные БАДы и необычные приспособления для приверженцев ЗОЖа. Один из самых популярных продуктов компании - японские грибы Голден Рейши. По утверждению продавца, грибы оказывают благотворное влияние на организм и защищают от многих недугов. "
                //     },
                //     background: '',
                //     collor: '',
                //     night_theme: false,
                // },
                // {
                //     type: 'hr',
                //     background: '',
                //     collor: '',
                //     night_theme: false,
                // },
                // {
                //     type: 'text',
                //     content: {
                //         title: "Проанализировали бизнес клиента",
                //         description: "Еще на старте разработки нового сайта мы столкнулись с проблемой: продукт заказчика дорогой, специфичный и нишевый.  \n" +
                //             "Целевая аудитория - люди со средним или высоким достатком, которые заботятся о своем здоровье и внешности. В основном это женщины, которые не просто хотят быть здоровыми прямо сейчас, но и собираются поддерживать свой организм в последующие годы. Для этого они отдают предпочтение БАДам, но подходят к их выбору очень внимательно. \n" +
                //             "Нам пришлось потратить много времени и сил, чтобы сделать хорошее позиционирование товара на новом сайте. Информацию о продукте нужно подать таким образом, чтобы у клиента не осталось ни единого сомнения о полезных свойствах японских грибов. "
                //     },
                //     background: '',
                //     collor: '',
                //     night_theme: false,
                // },
                // {
                //     type: 'reviews',
                //     content: {
                //         id: "54",
                //         content: "То же самое проделайте с косвенными конкурентами (это могут быть группы в соцсетях или альтернативные решения проблемы). Опишите все то же самое, плюс расскажите о пользовательском пути, который проходит клиент, когда решает проблему через этого конкурента.",
                //         author: {
                //             "name": "Герасимов Олег",
                //             "job": "Генеральный директор, 1000 и одна туфелька",
                //             "img": "https://viktordronboy.stage-backend.makeit.makeit-dev.ru/upload/iblock/998/pcxbvu2lw71ssj4s38g3fshfwq1x3b3e.png"
                //         }
                //     },
                //     background: '',
                //     collor: '',
                //     night_theme: false,
                // }
            ],
        } as ICaseDetail,
        isLoading: false as boolean,
    }),
    actions: {
        setCaseDetail(caseDetail: ICaseDetail | null) {
            this.caseDetail = caseDetail;
        },

        // case detail
        async findCase(id: number | string | string[]): Promise<ICaseDetail | null> {
            if (IS_DEV) {
                this.setCaseDetail(this.mockCaseDetail);
                return this.caseDetail;
            } else {
                this.isLoading = true;

                try {
                    const data: ICaseDetail = await $fetch(
                        `/api/cases/${id}`
                    );

                    if (!data) {
                        this.setCaseDetail(null);
                        return null;
                    } else {
                        this.setCaseDetail(data);
                        return data;
                    }
                } catch (error) {
                    console.error('ERROR on findCase: ', error);
                    showError({ message: 'Page not found', statusCode: 404 });
                    return null;
                } finally {
                    this.isLoading = false;
                }
            }
        },
    },
});
