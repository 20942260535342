<template>
    <div class="video">
	    <div v-if="isLoader && isLoading" class="video__preloader">
		    <div class="sk-fading-circle">
			    <div class="sk-circle sk-circle-1"></div>
			    <div class="sk-circle sk-circle-2"></div>
			    <div class="sk-circle sk-circle-3"></div>
			    <div class="sk-circle sk-circle-4"></div>
			    <div class="sk-circle sk-circle-5"></div>
			    <div class="sk-circle sk-circle-6"></div>
			    <div class="sk-circle sk-circle-7"></div>
			    <div class="sk-circle sk-circle-8"></div>
			    <div class="sk-circle sk-circle-9"></div>
			    <div class="sk-circle sk-circle-10"></div>
			    <div class="sk-circle sk-circle-11"></div>
			    <div class="sk-circle sk-circle-12"></div>
		    </div>
	    </div>
        <client-only>
            <VideoPlayer
                v-show="(isLoader && !isLoading) || !isLoader"
                :class="['video-js', videoClass ? videoClass : '']"
                :src="src"
                :options="videoOptions"
                @loadeddata="isLoading = false"
            >
                <template #default="{ player, state }">
                    <slot name="playBtn" :player="player" :state="state" />
                    <slot name="posterPreview" :player="player" :state="state" />
                    <slot name="repeatBtn" :player="player" :state="state" />
                </template>
            </VideoPlayer>
        </client-only>
    </div>
</template>

<script setup lang="ts">

const props = defineProps({
    src: { type: String, required: true, readonly: true },
    videoClass: { type: String, required: false, default: '', readonly: true },
    width: { type: Number, required: false, default: 0, readonly: true },
    height: { type: Number, required: false, default: 0, readonly: true },
    muted: { type: Boolean, required: false, default: false, readonly: true },
    playsinline: {
        type: Boolean,
        required: false,
        default: false,
        readonly: true,
    },
    autoplay: {
        type: Boolean,
        required: false,
        default: false,
        readonly: true,
    },
    loop: { type: Boolean, required: false, default: false, readonly: true },
    controls: {
        type: Boolean,
        required: false,
        default: false,
        readonly: true,
    },
    fluid: { type: Boolean, required: false, default: false, readonly: true },
	isLoader: { type: Boolean, required: false, default: false, readonly: true },
    poster: { type: String, required: false, default: '', readonly: true },
});

const isLoading = ref(true);
const videoOptions = {
	autoplay: props.autoplay,
	controls: props.controls,
	muted: props.muted,
	playsinline: props.playsinline,
	loop: props.loop,
	width: props.width,
	height: props.height,
	fluid: props.fluid,
	poster: props.poster,
	// sources: [
	// 	{ src: `${props.src}.mp4`, type: 'video/mp4' },
	// 	{ src: `${props.src}.ogv`, type: 'video/ogg' },
	// 	{ src: `${props.src}.webm`, type: 'video/webm' },
	// ]
};
</script>

<style lang="scss" src="~/assets/scss/components/ui/video.scss"></style>
