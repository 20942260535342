<template>
	<VueFinalModal
		:teleport-to="options.teleportTo"
		:content-transition="options.contentTransition"
		:overlay-transition="options.overflowTransition"
		:class="options.class"
		:content-class="options.contentClass"
		:overlay-class="options.overlayClass"
		:esc-to-close="options.escToClose"
		:click-to-close="options.clickToClose"
		:ssr="true"
		@opened="onOpen"
	>
		<div :class="['modal', modalClass ? `modal--${modalClass}` : '']">
			<slot />

			<Button
				v-if="!popup"
				class="modal__close-btn"
				:btn-form="'round'"
				:btn-size="'medium'"
				:btn-style="btnStyleClass"
				:btn-position="'absolute'"
				:icon-name="'cross'"
				@click="emit('close')"
			/>
			<Button
				v-else
				class="modal__close-btn"
				:btn-form="'round'"
				:btn-style="btnStyleClass"
				:btn-position="'absolute'"
				:icon-name="'cross'"
				@click="emit('close')"
			/>
		</div>
	</VueFinalModal>
</template>

<script setup lang="ts">
import Button from '~/components/ui/Button.vue';
import { VueFinalModal } from 'vue-final-modal';
import { useCommonStore } from '~/store';

const emit = defineEmits(['close']);
const indexStore = useCommonStore();

onMounted(() => {
	indexStore.openModal();
});

onBeforeUnmount(() => {
	indexStore.closeModal();
});

defineProps({
	options: { type: Object, required: true },
	modalClass: { type: String, required: true },
	btnStyleClass: { type: String, required: false, default: 'secondary' },
	popup: { type: Boolean, required: false, default: false }
});

function onOpen() {
	// if (process.client) {
	// 	const wrap = document.querySelector(`.modal-content`);
	// 	wrap?.removeAttribute(`tabindex`);
	// }
}
</script>

<style lang="scss" src="~/assets/scss/components/ui/modal.scss"></style>
