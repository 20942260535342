<template>
	<Transition name="fade">
		<div v-if="showPopup" class="popup popup--cookie p-24">
			<div class="f-h4 m-b-16">Мы используем куки</div>
            <div class="f-body m-b-16">Это необходимо для его стабильной работы и анализа трафика.</div>
			<Button
				:btn-size="'medium'"
				:btn-style="'primary-root'"
				@click="closePopup"
			>
				Отлично
			</Button>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import Button from '~/components/ui/Button.vue';
import { useCookies, globalCookiesConfig } from 'vue3-cookies';
const { cookies } = useCookies();
const showPopup = ref(false);

globalCookiesConfig({
	expireTimes: '30d',
	path: '/',
	domain: '',
	secure: true,
	sameSite: 'None',
});

onMounted(() => {
	if (cookies.get('cookie-popup') !== 'accepted') {
		showPopup.value = true;
	}
});

function closePopup() {
	cookies.set(
			'cookie-popup',
			'accepted',
	);
	showPopup.value = false;
}
</script>

<style
	lang="scss"
	src="~/assets/scss/components/ui/popup.scss"
	scoped
></style>
