import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import SwiperCore from 'swiper';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('Swiper', Swiper);
    nuxtApp.vueApp.component('SwiperSlide', SwiperSlide);

    SwiperCore.use([Autoplay, EffectFade, Pagination, Navigation]);
});
