import { useCommonStore, useSplinesStore, useCaseDetailStore } from '~/store';
import type { IMetaInfo } from '~/types';

export const seo = () => {
    const { metaInfo } = storeToRefs(useCommonStore());
    const { caseDetail } = storeToRefs(useCaseDetailStore());
    const { setPage } = useCommonStore();

    const route = useRoute();
    const url = useRequestURL();

    const page = computed(() => {
        return metaInfo.value.find(
            (el: IMetaInfo) => el.url.split('?')[0] === route.name
        ) || {};
    });

    function setSeoMeta() {
        if (route.name === 'cases-id' && caseDetail.value) {
            page.value.title = caseDetail.value.common?.seo?.title;
            page.value.description = caseDetail.value.common?.seo?.description;
            page.value.keywords = caseDetail.value.common?.seo?.keywords;
            page.value.ogTitle = caseDetail.value.common?.seo?.title;
            page.value.ogDescription = caseDetail.value.common?.seo?.description;
        }

        setPage(page.value);

        useHead(() => ({
            link: [{ rel: 'canonical', href: `${url.protocol}//${url.host}${route.path}` }],
        }));

        useSeoMeta({
            title: page.value.title,
            description: page.value.description,
            keywords: page.value.keywords,
            ogTitle: page.value.title,
            ogDescription: page.value.description,
            ogUrl: `${url.protocol}//${url.host}${route.path}`,
        });
    }

    setSeoMeta();

    watch(
        () => route.name,
        (newValue) => {
            if (newValue) {
                setSeoMeta();
            }
        },
        { immediate: false }
    );
}
