<template>
    <div class="feedback-form w-max-870">
        <template v-if="isSuccess">
            <h2
	            :class="[
					'm-b-24 f-h1 f-h1-5-mob',
					isModal ? 'c-gradient' : ''
				]"
            >
	            Спасибо за обращение!
            </h2>
            <div class="feedback-form__success f-lead">
                Наш менеджер изучит предоставленную информацию и свяжется с вами
                в ближайшее время
            </div>
        </template>
        <template v-else>
            <h2
	            :class="[
					'm-b-24 f-h1 f-h1-5-mob',
					isModal ? 'c-gradient' : ''
				]"
            >
                Расскажите о&nbsp;проекте и&nbsp;мы предложим решение
            </h2>
            <!--			<Button-->
            <!--				class="m-b-40"-->
            <!--				:btn-size="'medium'"-->
            <!--				:btn-style="'secondary-fill'"-->
            <!--				:imgBtn="'/images/chat.svg'"-->
            <!--			>-->
            <!--				Написать чатботу-->
            <!--			</Button>-->
            <div class="form">
	            <client-only>
	                <form @submit="onSubmit">
	                    <Grid :grid-style="'form'" :array="feedbackForm">
	                        <template #gridItem="{ item }">
	                            <FormTextarea
	                                v-if="item.type === 'textarea'"
                                    :name="item.name"
	                                :label="item.label"
	                                :error="errors[item.type]"
	                                :night="isNightTheme"
	                                v-bind="textarea"
	                            />
	                            <template v-else-if="item.type === 'file'">
	                                <FormFileInput
	                                    :isModal="isModal"
                                        :name="item.name"
	                                    :label="item.label"
	                                    :error="errors[item.type]"
	                                    @change="onFileChanged"
	                                />
	                            </template>

	                            <template v-else>
	                                <FormInput
	                                    v-if="item.type === 'name'"
                                        :name="item.name"
	                                    :label="item.label"
	                                    :error="errors[item.type]"
	                                    :night="isNightTheme"
	                                    v-bind="name"
	                                />
	                                <FormInput
	                                    v-else-if="item.type === 'tel'"
	                                    :type="item.type"
                                        :name="item.name"
	                                    :label="item.label"
	                                    :error="errors[item.type]"
	                                    :night="isNightTheme"
	                                    v-bind="tel"
	                                />
	                                <FormInput
	                                    v-else-if="item.type === 'email'"
	                                    :type="item.type"
                                        :name="item.name"
	                                    :label="item.label"
	                                    :error="errors[item.type]"
	                                    :night="isNightTheme"
	                                    v-bind="email"
	                                />
	                            </template>
	                        </template>
	                    </Grid>

	                    <div
	                        class="feedback-form__submit m-t-64-lg m-t-64-dp m-t-64-tab m-t-40-mob"
	                    >
	                        <Button
	                            class="feedback-form__submit-btn"
	                            :btn-style="isModal ? 'grad' : 'primary'"
	                            :btn-size="'big'"
	                            :btn-type="'submit'"
	                            @click="onSubmit"
	                        >
	                            Отправить
	                        </Button>
	                        <div
	                            class="feedback-form__submit-text m-t-8 c-black-60 f-caption"
	                        >
	                            Нажав на кнопку, соглашаюсь на
	                            <NuxtLink
		                            :class="[
										'link',
										isModal ? 'link--white' : 'link--black'
		                            ]"
		                            to="policy"
                                    target="_blank"
	                            >
		                            обработку персональных данных
	                            </NuxtLink>
	                        </div>

	                        <div v-if="isError" class="form-error f-caption">
	                            Что-то пошло не так. Пожалуйста, попробуйте еще раз
	                            отправить форму через некоторое время.
	                        </div>
	                    </div>
	                </form>
	            </client-only>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import {
	TEL_MASK,
	FORM_VALIDATION_ERROR_MES,
} from '~/utils/constants';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import Button from '~/components/ui/Button.vue';
import FormInput from '~/components/ui/FormInput.vue';
import FormFileInput from '~/components/ui/FormFileInput.vue';
import FormTextarea from '~/components/ui/FormTextarea.vue';
import Grid from '~/components/ui/Grid.vue';
import { useFeedbackStore } from '~/store';
const feedbackStore = useFeedbackStore();

defineProps({
    isModal: { type: Boolean, default: false },
    isNightTheme: { type: Boolean, default: false },
});

const isSuccess = ref(false);
const isError = ref(false);

const files = ref<FileList | File[] | null>();
const form = ref<HTMLFormElement>();

function onFileChanged(data) {
    if (data) {
        files.value = data;
    }
}

const { handleSubmit, values, errors, defineComponentBinds } = useForm({
    validationSchema: yup.object().shape({
        name: yup.string().required(FORM_VALIDATION_ERROR_MES.REQUIRED),
        tel: yup
            .string()
            .min(TEL_MASK.length, FORM_VALIDATION_ERROR_MES.TEL_MIN_LENGTH)
	        .required(FORM_VALIDATION_ERROR_MES.REQUIRED),
        email: yup.string(),
        textarea: yup.string(),
        // file: yup.mixed()
        // 	.test('is-valid-type', FORM_VALIDATION_ERROR_MES.FILE_FORMAT,
        // 		value => {
        // 			console.log(value)
        // 			if (!value) {
        // 				return true;
        // 			} else {
        // 				return FILE_TYPES.indexOf(value.toLowerCase().split('.').pop()) > -1;
        // 			}
        // 		})
        // 	.test('is-valid-size', FORM_VALIDATION_ERROR_MES.FILE_MAX_SIZE,
        // 		(value, prop) => {
        // 			console.log(prop)
        // 			if (!value) {
        // 				return true;
        // 			} else {
        // 				return value.size <= MAX_FILE_SIZE;
        // 			}
        // 		})
    }),
});

const email = defineComponentBinds('email', {
    // validateOnValueUpdate: false,
});
const name = defineComponentBinds('name');
const tel = defineComponentBinds('tel');
const textarea = defineComponentBinds('textarea');

const feedbackForm = [
    {
        type: 'name',
        name: 'name',
        label: 'Представьтесь',
    },
    {
        type: 'tel',
        name: 'phone',
        label: 'Телефон',
        gridClass: 'half',
    },
    {
        type: 'email',
        name: 'email',
        label: 'Email/Telegram',
        gridClass: 'half',
    },
    {
        type: 'textarea',
        name: 'comment',
        label: 'Опишите задачу',
    },
    {
        type: 'file',
        name: 'file',
        label: 'Прикрепить файл',
    },
];


async function onSuccess(values: { [key: string]: string }) {
	let formData: FormData = new FormData();

	for (const [key, value] of Object.entries(values)) {
		if (key && value) {
			formData.append(key, value);
		}
	}

	if (files.value) {
		for (let x = 0; x < files.value.length; x++) {
			formData.append('files[]', files.value[x]);
		}
	}

	feedbackStore.sendFeedbackForm(formData).then((res) => {
		if (res) {
		    if (res.status === 'success') {
		        isSuccess.value = true;
		        isError.value = false;
		    } else if (res.status === 'error') {
		        isSuccess.value = false;
		        isError.value = true;
		    }
		} else {
			isSuccess.value = false;
			isError.value = true;
		}
	});
}

const onSubmit = handleSubmit(onSuccess);
</script>

<style
    lang="scss"
    src="~/assets/scss/components/forms/feedback-form.scss"
    scoped
></style>
