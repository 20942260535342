<template>
    <div class="page">

        <div v-show="pageLoading" class="loader-wrap">
            <div class="loader"></div>
        </div>

	    <Header />

	    <NuxtPwaManifest />
	    <NuxtLayout >
		    <NuxtPage />
	    </NuxtLayout>

	    <Footer />

	    <CookiePopup />
	    <ButtonUp />
    </div>

    <ModalsContainer />
</template>

<script setup lang="ts">
import { useNuxtApp } from 'nuxt/app';
import { useWindowScroll } from '@vueuse/core';
import Header from '~/components/layout/Header.vue';
import Footer from '~/components/layout/Footer.vue';
import ButtonUp from '~/components/ui/ButtonUp.vue';
import CookiePopup from '~/components/popups/CookiePopup.vue';
import { ModalsContainer } from 'vue-final-modal';
import { useCommonStore, useSplinesStore } from '~/store';
import { seo } from '~/composables/seo';

const nuxtApp = useNuxtApp();
const { fetchCommonInfo, fetchMetaInfo, setScrollBarWidth } = useCommonStore();
const { fetchSplines } = useSplinesStore();
const { y } = useWindowScroll(window);
const { pageLoading } = storeToRefs(useCommonStore());

nuxtApp.hook('page:loading:end', () => {
    scrollBarWidth();

	// if (!route.hash) {
	// 	window.scrollTo(0, 0);
	// }

    /* ДЛЯ СРАБАТЫВАНИЯ AOS ЭФФЕКТА, ПРИДУМАТЬ ДРУГОЙ СПОСОБ ПЕРЕЗАГРУЖАТЬ АНИМАЦИИ */
    // window.dispatchEvent(new Event('resize'));
    // setTimeout(() => {
	    pageLoading.value = false;
	// }, 100);
});

await useAsyncData(
    () => (
        fetchCommonInfo(),
        fetchMetaInfo(),
        fetchSplines()
    ),
);

const route = useRoute();
nextTick(() => {
    if (route.name !== 'cases-id') {
        seo();
    }
});
// создадим элемент с прокруткой
function scrollBarWidth() {
    if (process.client) {
        let div = document.createElement(`div`);

        div.style.overflowY = `scroll`;
        div.style.width = `50px`;
        div.style.height = `50px`;

        // мы должны вставить элемент в документ, иначе размеры будут равны 0
        document.body.append(div);
        let scrollWidth: number = div.offsetWidth - div.clientWidth;
        div.remove();

        setScrollBarWidth(scrollWidth);
        document.documentElement.style.setProperty(
            `--scrollbar`,
            ``.concat(scrollWidth.toString(), `px`)
        );
    }
}

function getViewport() {
    if (process.client) {
        let ww = window.screen.availWidth;
        let vps = ``;

        if (ww <= 500) {
            if (window.orientation === 0) {
                vps = `width=375, user-scalable=no`;
            } else {
                vps = `width=768, user-scalable=no`;
            }
        } else if (ww <= 768) {
            vps = `width=768, user-scalable=no`;
        } else {
            vps = `width=device-width, initial-scale=1`;
        }

        return vps;
    }

    return `width=device-width, initial-scale=1`;
}

function setViewport() {
    let viewport = document.querySelector(`meta[name=viewport]`);
    viewport?.setAttribute(`content`, getViewport());
}

onMounted(() => {
    if (process.client) {
        window.addEventListener('resize', setViewport);
    }
});

onUnmounted(() => {
    if (process.client) {
        window.removeEventListener('resize', setViewport);
    }
});
</script>

<style lang="scss" src="~/assets/scss/global.scss"></style>
<style lang="scss" src="~/assets/scss/components/ui/loader.scss"></style>
