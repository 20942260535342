<template>
    <div
        :class="[
            'form-textarea',
            night ? 'form-textarea--night' : '',
            error ? 'input-error' : '',
        ]"
    >
        <textarea
            :name="name"
            :placeholder="label"
            rows="1"
            :value="modelValue"
            @input="onInput"
            @blur="onBlur"
        />

        <div class="form-error f-caption">{{ error }}</div>
    </div>
</template>

<script setup lang="ts">
defineProps({
    name: { type: String, required: false, readonly: true },
    label: { type: String, required: false, readonly: true },
    error: { type: String, readonly: true },
    night: { type: Boolean, readonly: true },
    modelValue: { type: String },
});

const emit = defineEmits(['update:modelValue', 'blur']);
const onInput = (e) => {
    e.target.style.height = 0;
    e.target.style.height = e.target.scrollHeight + `px`;

    emit('update:modelValue', e.target.value);
};

const onBlur = (e) => emit('blur');
</script>

<style
    lang="scss"
    src="~/assets/scss/components/ui/form-textarea.scss"
    scoped
></style>
