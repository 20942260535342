import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/makeitapp/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/makeitapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/makeitapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/makeitapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/makeitapp/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/makeitapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/makeitapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/makeitapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/makeitapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/makeitapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/makeitapp/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/makeitapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/makeitapp/.nuxt/pwa-icons-plugin.ts";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/makeitapp/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34 from "/makeitapp/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_client_kNbg80s6qe3kc87z2nZCvKsWnzwgKnky7UDHZZ7lmIY from "/makeitapp/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import click_outside_I4ttNMqHV6xHGjmJUbCn_KeHhF4cThlycOt7HfJGaS4 from "/makeitapp/plugins/click-outside.ts";
import inputmask_IXgn_A8G7EaVeVHz9CrqM_mCdllqeay62gQHJZGvTNs from "/makeitapp/plugins/inputmask.ts";
import modal_I_HQdWtjAMkihB994bRSOtsiTPZV2SAQ_iERvLViCAg from "/makeitapp/plugins/modal.ts";
import simplebar__tWa6d8vrzdIBGaSthQuE59CLILscpYpUMHQDB1SreY from "/makeitapp/plugins/simplebar.ts";
import swiper_DEKIm450FPSJ9lhHF_13K0PXm20AZzWqFcOa6fXc9fw from "/makeitapp/plugins/swiper.ts";
import thtml_uNd82MtVZOuJ3jC0px6sampW4zlOq9b_xIafPLNUx_4 from "/makeitapp/plugins/thtml.ts";
import video_player_l_xnHphgJkXw5mIsOs_vIM_sjNLpVQzviSSeNVxdSII from "/makeitapp/plugins/video-player.ts";
import vue_aos_NqCqZTaP7Zoy9AsBM8fG8MAJSn0pXd5cz_G1O9TieSk from "/makeitapp/plugins/vue-aos.ts";
import yandex_map_rsrL63kytH6LYyj1VUb69_jzmlY7EDM6xfVhDnpJqvA from "/makeitapp/plugins/yandex-map.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ,
  plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34,
  plugin_client_kNbg80s6qe3kc87z2nZCvKsWnzwgKnky7UDHZZ7lmIY,
  click_outside_I4ttNMqHV6xHGjmJUbCn_KeHhF4cThlycOt7HfJGaS4,
  inputmask_IXgn_A8G7EaVeVHz9CrqM_mCdllqeay62gQHJZGvTNs,
  modal_I_HQdWtjAMkihB994bRSOtsiTPZV2SAQ_iERvLViCAg,
  simplebar__tWa6d8vrzdIBGaSthQuE59CLILscpYpUMHQDB1SreY,
  swiper_DEKIm450FPSJ9lhHF_13K0PXm20AZzWqFcOa6fXc9fw,
  thtml_uNd82MtVZOuJ3jC0px6sampW4zlOq9b_xIafPLNUx_4,
  video_player_l_xnHphgJkXw5mIsOs_vIM_sjNLpVQzviSSeNVxdSII,
  vue_aos_NqCqZTaP7Zoy9AsBM8fG8MAJSn0pXd5cz_G1O9TieSk,
  yandex_map_rsrL63kytH6LYyj1VUb69_jzmlY7EDM6xfVhDnpJqvA
]